import React from "react";

const WrongPage = () => {
  return (
    <>
      <div id="mainBody">
        <div id="main">
          <div className="fof">
            <h1>Error 404</h1> <span><small>Page Not Found</small></span><br/>
            <a id="back" href="/">Back to Homepage</a>
          </div>
        </div>
      </div>
    </>
  );
};


export default WrongPage
